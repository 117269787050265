import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { HomeworkModel } from "@/models";
import { TeCommon } from "../Common";

export class PaperForm {
    name = '';
    date = '';
    city = '';
    school = '';
    class = '';
    teacher = "";
    grade = '';
    level = "";
    unit = "";
    num = 0;
    active = "";
    hid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkPaperAddController>({
    components: {
        Header, Footer
    }
})
export default class ApTeacherHomeworkPaperAddController extends Vue {
    /*定義data 變數 */
    private paperForm = new PaperForm();
    private name = window.localStorage.getItem('teacher_name') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    public async created() {
        const item: { [key: string]: string } = {
            hid: this.$route.query.hid as string,
            token: this.token,
        }
        const editData = await HomeworkModel.teGetPaper(item) as response;
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: response) {
        TeCommon.checkToken(data.ERR_CODE as number);

        const item = data.info as {
            hid: string;
            name: string;
            date: string;
            city: string;
            school: string;
            class: string;
            teacher: string;
            grade: string;
            unit: string;
            level: string;
            num: number;
            active: string;
        };
        this.paperForm.hid = item.hid;
        this.paperForm.name = item.name;
        this.paperForm.date = item.date;
        this.paperForm.city = item.city;
        this.paperForm.school = item.school;
        this.paperForm.class = item.class;
        this.paperForm.teacher = item.teacher;
        this.paperForm.grade = item.grade;
        this.paperForm.unit = item.unit;
        this.paperForm.level = item.level;
        this.paperForm.num = item.num;
        this.paperForm.active = item.active;
    }

}